import { createUserWithEmailAndPassword, GoogleAuthProvider,signInWithEmailAndPassword, signOut,signInWithPopup ,OAuthProvider } from "firebase/auth"
import { auth } from "../config/firebase/firebase"



export const createUser = async (email, password,signUpType="email") =>
{
    if(signUpType=="email")
     {
        return createUserWithEmailAndPassword(auth, email, password)
     }
     else if(signUpType=="google")
     {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            'prompt': 'select_account'
        });
        return signInWithPopup(auth, provider)
     }

     else
     {
        const provider = new OAuthProvider('microsoft.com')
         provider.setCustomParameters({
            prompt: "consent",
            tenant:"common"
        });
      return signInWithPopup(auth, provider);


     }
    }
export const signInUser = async (email, password) => signInWithEmailAndPassword(auth, email, password)
export const signOutUser = async () => signOut(auth)

export const signInWithMicrosoft = async () => {
    try {
        const provider = new OAuthProvider('microsoft.com');
        const userCredential = await signInWithPopup(auth, provider);
        return userCredential.user;
    } catch (error) {
        // Handle error
        console.error("Error signing in with Microsoft:", error);
        throw error;
    }
};
