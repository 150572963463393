import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: true,
};

export const setNotificationSlice = createSlice({
  name: "setNotification",
  initialState,
  reducers: {
    setNotificationData: (state, action) => {
      state.data = action.payload.data || [];
      state.loading = false
    },
  },
});

export const { setNotificationData } = setNotificationSlice.actions;

export default setNotificationSlice.reducer;
