import { createSlice } from '@reduxjs/toolkit';
import { getVendorCategoryDetails } from './vendorAction';

const initialState = {
    category: '',
    vendor: '',
    vendorDetails: {},
    searchName: "",
    contractDetails : {},
    vendorCategoriesList:[],
    categoryQuizResponseId: '',
    categoryQuizFormId: '',

    suggestedVendorsEventIds: [],

    fetchingSuggestedVendors: undefined,
    suggestedVendors: [],
    suggestedVendorsErrorMessage: '',
    lastRecommendationsErrorMessage: '',
    onlyLocalVendors: undefined,
    favouriteVendors: []
};

export const vendorSlice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        selectedCategory: (state, action) => {
            let { payload } = action;
            state.category = payload.category || '';
        },
        setSelectedVendorId: (state, action) => {
            let { payload } = action;
            state.vendor = payload.vendor || '';
        },
        setSelectedVendorDetails: (state, action) => {
            let { payload } = action;
            state.vendorDetails = payload.vendorDetails || '';
            state.contractDetails = payload.contractDetails || {}
        },
        setSearchName: (state, action) => {
            let { payload } = action;
            state.searchName = payload.searchName || '';
        },
        setCategoryQuizResponseId: (state, action) => {
            const {formId, responseId} = action.payload
            state.categoryQuizResponseId = responseId
            state.categoryQuizFormId = formId
        },
        setSuggestedVendors: (state, action) => {
            const {
              suggestedVendors,
              suggestedVendorsEventIds,
              success,
              message,
              type,
              onlyLocalVendors,
              favouriteVendorIds = [],
            } = action.payload;

            suggestedVendors.forEach(vendor => {
                vendor.favourite = favouriteVendorIds.includes(vendor.id)
            })


            state.fetchingSuggestedVendors = false
            if ('fetchingSuggestedVendors' in action.payload) {
                state.fetchingSuggestedVendors = action.payload.fetchingSuggestedVendors
            }

            state.suggestedVendors = suggestedVendors
            state.suggestedVendorsEventIds = suggestedVendorsEventIds
            state.onlyLocalVendors = onlyLocalVendors

            const errorKey = type === 'lastRecommendations' ? 'lastRecommendationsErrorMessage' : 'suggestedVendorsErrorMessage'
            state[errorKey] = success ? '' : message
        },
        setSuggestedVendorsErrorMessage: (state, action) => {
            state.suggestedVendorsErrorMessage = state.lastRecommendationsErrorMessage = action.payload.message
        },
        setFetchingSuggestedVendors: (state, action) => {
            state.fetchingSuggestedVendors = action.payload.fetchingSuggestedVendors
        },
        setFavouriteVendor: (state, action) => {
            const { vendorInfo, isFavourite } = action.payload
            const vendorId = vendorInfo?.id

            state.suggestedVendors = state.suggestedVendors.map(v => {
                if (v.id === vendorId) {
                    v.favourite = isFavourite
                }
                return v
            })

            if (isFavourite) {
                const vendor = state.favouriteVendors.find(v => v.id === vendorId)
                if (vendor) {
                    vendor.favourite = true
                } else {
                    vendorInfo.favourite = true
                    state.favouriteVendors.push(vendorInfo)
                }
            } else {
                state.favouriteVendors = state.favouriteVendors.filter(v => v.id !== vendorId)
            }
        },
        setFavouriteVendors: (state, action) => {
            const vendors = action.payload
            vendors.forEach(v => v.favourite = true)

            state.favouriteVendors = [...vendors]

            const vendorIds = vendors.map(v => v.id)
            state.suggestedVendors = state.suggestedVendors.map(v => {
                v.favourite = vendorIds.includes(v.id)
                return v
            })
        }
    },
    extraReducers: {
        [getVendorCategoryDetails.fulfilled]: (state, action) => {
            state.vendorCategoriesList = action.payload;
        },
    },
})

export const {
    selectedCategory,
    setSelectedVendorId,
    setSelectedVendorDetails,
    setSearchName,
    setCategoryQuizResponseId,
    setSuggestedVendors,
    setSuggestedVendorsErrorMessage,
    setFetchingSuggestedVendors,
    setFavouriteVendor,
    setFavouriteVendors,
} = vendorSlice.actions

export default vendorSlice.reducer