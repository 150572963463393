export const errorCodes = {
    "auth/admin-restricted-operation": {
        "code": "ADMIN_ONLY_OPERATION",
        "message": "This operation is restricted to administrators only."
    },
    "auth/argument-error": {
        "code": "ARGUMENT_ERROR",
        "message": "An error occurred."
    },
    "auth/app-not-authorized": {
        "code": "APP_NOT_AUTHORIZED",
        "message": "This application is not authorized"
    },
    "auth/captcha-check-failed": {
        "code": "CAPTCHA_CHECK_FAILED",
        "message": "The reCAPTCHA check failed."
    },
    "auth/code-expired": {
        "code": "CODE_EXPIRED",
        "message": "The code has expired."
    },
    "auth/credential-already-in-use": {
        "code": "CREDENTIAL_ALREADY_IN_USE",
        "message": "This credential is already in use."
    },
    "auth/custom-token-mismatch": {
        "code": "CREDENTIAL_MISMATCH",
        "message": "The custom token corresponds to a different audience."
    },
    "auth/email-already-in-use": {
        "code": "EMAIL_EXISTS",
        "message": "Email is already existing."
    },
    "auth/expired-action-code": {
        "code": "EXPIRED_OOB_CODE",
        "message": "The action code has expired."
    },
    "auth/internal-error": {
        "code": "INTERNAL_ERROR",
        "message": "An internal error has occurred."
    },
    "auth/invalid-argument": {
        "code": "INVALID_ARGUMENT",
        "message": "An invalid argument was provided."
    },
    "auth/invalid-credential": {
        "code": "INVALID_CREDENTIAL",
        "message": "The provided credential is invalid."
    },
    "auth/invalid-email": {
        "code": "INVALID_EMAIL",
        "message": "The email address is invalid."
    },
    "auth/invalid-password": {
        "code": "INVALID_PASSWORD",
        "message": "The password is invalid."
    },
    "auth/invalid-verification-code": {
        "code": "INVALID_OOB_CODE",
        "message": "The provided action code is invalid."
    },
    "auth/invalid-verification-id": {
        "code": "INVALID_OOB_CODE",
        "message": "The provided action code is invalid."
    },
    "auth/language-code-invalid": {
        "code": "INVALID_LANGUAGE_CODE",
        "message": "The language code is invalid or not supported."
    },
    "auth/limit-exceeded": {
        "code": "LIMIT_EXCEEDED",
        "message": "too many requests have been made using this credential."
    },
    "auth/missing-android-pkg-name": {
        "code": "MISSING_ANDROID_PACKAGE_NAME",
        "message": "An Android Package Name must be provided if the Android App is required."
    },
    "auth/missing-continue-uri": {
        "code": "MISSING_CONTINUE_URI",
        "message": "A continue URL must be provided in the request."
    },
    "auth/unauthorized-domain": {
        "code": "INVALID_ORIGIN",
        "message": "Unauthorized domain"
    },
    "auth/account-exists-with-different-credential": {
        "code": "CREDENTIAL_ALREADY_IN_USE",
        "message": "An account already exists with the same email address."
    },
    "auth/user-not-found": {
        "code": "USER_NOT_FOUND",
        "message": "It seems you have not yet registered to DesiWeds. You can begin the registration process here."
    },
    "auth/user-disabled": {
        "code": "USER_DISABLED",
        "message": "The user has been disabled by an administrator."
    },
    "auth/popup-blocked": {
        "code": "POPUP_BLOCKED",
        "message": "Popup was blocked by browser."
    },
    "auth/operation-not-allowed": {
        "code": "OPERATION_NOT_ALLOWED",
        "message": "This operation is not allowed. Please contact your administrator."
    },
    "auth/requires-recent-login": {
        "code": "REQUIRES_RECENT_LOGIN",
        "message": "This operation is sensitive and requires recent authentication. Log in again before retrying this request."
    },
    "auth/popup-closed-by-user": {
        "code": "POPUP_CLOSED_BY_USER",
        "message": "The popup has been closed by the user before finalizing the operation."
    },
    "auth/provider-already-linked": {
        "code": "PROVIDER_ALREADY_LINKED",
        "message": "User can only be linked to one identity for the given provider."
    },
    "auth/redirect-cancelled-by-user": {
        "code": "REDIRECT_CANCELLED_BY_USER",
        "message": "The redirect operation has been canceled by the user."
    },
    "auth/redirect-operation-pending": {
        "code": "REDIRECT_OPERATION_PENDING",
        "message": "A redirect operation is already pending."
    },
    "auth/timeout": {
        "code": "TIMEOUT",
        "message": "The operation has timed out."
    },
    "auth/user-token-expired": {
        "code": "USER_TOKEN_EXPIRED",
        "message": "The user's credential is no longer valid. Please sign in again."
    },
    "auth/too-many-requests": {
        "code": "TOO_MANY_REQUESTS",
        "message": "Too many requests have been made for this user."
    },
    "auth/unverified-email": {
        "code": "UNVERIFIED_EMAIL",
        "message": "The email address is not verified."
    },
    "auth/user-cancelled": {
        "code": "USER_CANCELLED",
        "message": "User did not grant permission."
    },
    "auth/user-signed-out": {
        "code": "USER_SIGNED_OUT",
        "message": "User is signed out."
    },
    "auth/weak-password": {
        "code": "WEAK_PASSWORD",
        "message": "The password must be at least 6 characters."
    },
    "unknown": {
        "code": "FINALLY",
        "message": "Something went wrong."
    },
    "auth/missing-email": {
        "code": "MISSING_EMAIL",
        "message": "The email address is missing."
    },
    "auth/wrong-password": {
        "code": "INVALID_PASSWORD",
        "message": "Your email address or password is incorrect. Please check and try again."
    },
    "SOMETHING_WENT_WRONG": {
        "code": "SOMETHING_WENT_WRONG",
        "message": "Something went wrong."
    },
    "TOO_MANY_ATTEMPTS_TRY_LATER": {
        "code": "TOO_MANY_ATTEMPTS_TRY_LATER",
        "message": "Too many attempts, Please try again later."
    },
    "invalid-argument": {
        "code": "USER_NOT_FOUND",
        "message": "It seems you have not yet registered to DesiWeds. You can begin the registration process here."
    },
    "auth/invalid-login-credentials": {
        "code": "USER_NOT_FOUND",
        "message": "It seems you have not yet registered to DesiWeds. You can begin the registration process here."
    },


}
export default errorCodes;