
const LogoNew = () => {
  return (
    <div className="logo">
      <img className="img-fluid" src="/images/logo.png" alt="logo" />
    </div>
  );
};

export default LogoNew;
