import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tabOpen: 1,
    editBudget: false,
    pendingTodoList: [],
    fromDashboard: false,
    vendorCategoriesList: [],
    success: false,
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setTab: (state, action) => {
            let { payload } = action;
            state.tabOpen = payload.tabOpen || 1;
        },
        setEditBudget: (state, action) => {
            let { payload } = action;
            state.editBudget = payload.editBudget || false;
        },
        setPendingTodoList: (state, action) => {
            let { payload } = action;
            state.pendingTodoList = payload.pendingTodoList || [];
        },
        setFromDashboard: (state, action) => {
            let { payload } = action;
            state.fromDashboard = payload.fromDashboard || false;
        },
        setVendorCategoriesList: (state, action) => {
            let { payload } = action;
            state.vendorCategoriesList = payload.vendorCategoriesList || [];
        },
        setSuccess: (state, action) => {
            let { payload } = action;
            state.success = payload.success || false;
        },
    }
})

export const { setTab, setEditBudget, setPendingTodoList, setFromDashboard, setVendorCategoriesList, setSuccess } = dashboardSlice.actions

export default dashboardSlice.reducer