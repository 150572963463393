'use strict'

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/firebase/firebase";

/**
 * Create a new entry with this method
 * attach data into the body of the request
 * @param {string} url - The url to send the request
 * @param {object} data - The data to send
 * @returns {Promise<any>} - The promise of the request
 * @memberof req
 */
export const post = async (url = '', data = {}, isPDFData = false, keepAlive = false) => {
    if (!url) return Promise.reject('NoUrl');
    return fetchData(url, 'POST', data, isPDFData, keepAlive)
}

/**
 * Get a resource with this method
 * Attach data to the url as a query string (?key=value&key=value) or url params (/:key/:value)
 * @param {string} url - The url to send the request
 * @returns {Promise<any>} - The promise of the request
 * @memberof req
 */

export const get = async (url = '') => {
    if (!url) return Promise.reject('NoUrl');
    return fetchData(url, 'GET')
}

/**
 * Update a resource with this method (PUT)
 * Use PUT to update a resource with a new data object replacing the old one
 * attach the data to the request as a json object
 *
 * @param {string} url
 * @param {object} data
 * @param {string} method
 * @returns {Promise<any>}
 * @memberof req
 */

export const put = async (url = '', data = {}) => {
    if (!url) return Promise.reject('NoUrl');
    return fetchData(url, 'PUT', data)
}

/**
 * Update a resource with this method
 * Use PATCH when you want to update a part of the resource
 * attach the data to the request as a json object
 *
 * @param {*} url
 * @param {*} data
 * @returns
 */
export const patch = async (url = '', data = {}) => {
    if (!url) return Promise.reject('NoUrl');
    return fetchData(url, 'PATCH', data)
}

/**
 * Delete resource with this method
 * Call this method to delete a resource whether its hard delete or soft delete
 * Attach data to the url as a query string (?key1=value1&key2=value2) or url params (/:key/:value)
 * @param {string} url - url to delete
 * @returns {Promise<any>}
 * @memberof req
 */
export const del = async (url = '') => {
    if (!url) return Promise.reject('NoUrl');
    return fetchData(url, 'DELETE')
}

/**
 *
 * @param {*} url
 * @param {*} method GET | POST | PUT | DELETE
 * @param {Object} data  JSON object
 * @returns {Promise<any>}
 * @memberof req
 */

const fetchData = async (url = '', method = 'GET', data = {}, isPDFData = false, keepAlive = false) => {
    if (!url) return Promise.reject('NoUrl');
    if (!method) return Promise.reject('NoMethod');
    const _content = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        }
    };

    if (keepAlive) {
        _content.keepalive = keepAlive;
    }

    let user = auth?.currentUser
    if (user) {
        const token = await auth?.currentUser?.getIdToken();
        if (token) {
            _content.headers["Authorization"] = `Bearer ${token}`;
        }
    } else {
        const token = await _getIdTokenFromOnAuthStateChanged();
        if (token) {
            _content.headers["Authorization"] = `Bearer ${token}`;
        }
    }

    if (data && method === 'POST' || method === 'PUT' || method === 'PATCH') {
            _content.body = JSON.stringify(data)

    }
    return new Promise(async (resolve, reject) => {
        try {
            let unresolvedResponse = await fetch(url, _content).catch(err => {
                return reject(err)
            })
            let response = isPDFData? unresolvedResponse :  await unresolvedResponse.json().catch(err => {
                return reject(err)
            })

            if (unresolvedResponse.ok) {
                return resolve(response)
            } else {
                return reject(response)
            }
        } catch (err) {
            return reject(err)
        }
    })
}

async function _getIdTokenFromOnAuthStateChanged() {
    return new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = await user.getIdToken();
                return resolve(token);
            } else {
                return resolve(false);
            }
        })
    });
}

export default fetchData;