/**
 * This File contains two main components
 * 1. Auth Context
 *      - a react context to share auth & permission data among components
 *      - following data are loaded
 *          - isAuthenticated : boolean flag indicating login status of the user
 *          - user : user profile object
 *          - isLoading : boolean flag indicating whether authentication check is still loading
 *          - permissions : permission object indicating permissions available for the user
 *          - mainEvent : user mainEvent object
 *          - refreshContext : promise function to reload all the above context data if required
 * 2. useAuth - a hook component that allows to load data across the components
 * @author Emvigo Technologies
 */
import React, { createContext, useContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { getUserByEmail } from "../../models/users";
import { getUserPermissions } from "../../models/permissions";
import { authStateUserData } from "../../modules/auth";

const AuthContext = createContext()

function AuthProvider({ children }) {
    const { pathname } = useRouter()
    const [user, setUser] = useState(null)
    const [permissions, setPermissions] = useState({})
    const [mainEvent, setMainEvent] = useState({})
    const [isLoading, setLoading] = useState(true)

    const loadDefaults = () => {
        return new Promise(async (resolve) => {
            try {
                const { user: currentUser } = await authStateUserData().catch((_err) => {
                    setUser(null)
                    setLoading(false)
                })
                if (!currentUser) {
                    setUser(null)
                    setLoading(false)
                    return resolve(true)
                }
                const profile = await getUserByEmail(currentUser.email.toLowerCase());
                const userPermissions = await getUserPermissions(profile)
                setUser(profile)
                setPermissions(userPermissions?.permissions?.pwa || {})
                setMainEvent(userPermissions?.mainEvent || {})
                resolve(true)
            } catch (e) {
                return false
            }
        })
    }

    async function getUser() {
        try {
            setLoading(true)

            const { user: currentUser } = await authStateUserData().catch((_err) => {

                setUser(null)
                setLoading(false)
            })

            if (!currentUser) {

                setUser(null)
                setLoading(false)
                return
            }

            const profile = await getUserByEmail(currentUser.email.toLowerCase());
            const userPermissions = await getUserPermissions(profile)

            setUser(profile)
            setPermissions(userPermissions?.permissions?.pwa || {})
            setMainEvent(userPermissions?.mainEvent || {})
            setLoading(false)
        } catch (err) {
            return false
        }
    }

    // Following useEffect code block retrieves user profile data & permissions
    useEffect(() => {
        getUser()
    }, [pathname])


    return (
        <AuthContext.Provider value={{ isAuthenticated: !!user, user, isLoading, permissions, mainEvent, refreshContext: loadDefaults }}>{children}</AuthContext.Provider>
    )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }