import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSubCollectionById } from '../../models';

export const getTodoDetails = createAsyncThunk(
  'todo/details',
  async ({ subId, mainId, userType }, { rejectWithValue }) => {
    try {
      const todoDetails = await getSubCollectionById({ collectionName: 'MainEvents', mainId, subCollectionName: 'Todos', subId });
      return {
        ...todoDetails,
        createdAt: todoDetails?.createdAt.toDate()?.toDateString(),
        updateAt: todoDetails?.updateAt.toDate()?.toDateString(),
        dueDate: todoDetails?.dueDate.toDate()?.toISOString(),
        assiginedToObj: getAssignedToObject(todoDetails?.assiginedTo, userType)
      }
    } catch (error) {
      return rejectWithValue({ code: error.code, message: error.message });
    }
  }
);

const getAssignedToObject = (key, userType) => {
  switch (key) {
    case "Bride":
      return userType === "Bride" ? { value: 'self', label: 'Self' } : { value: 'partner', label: 'Partner' }
    case "Groom":
      return userType === "Groom" ? { value: 'self', label: 'Self' } : { value: 'partner', label: 'Partner' }
    case "Family":
      return { value: 'family', label: 'Family /Friend' }
    default:
      break;
  }
}