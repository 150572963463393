import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../../../config/firebase/firebase";
import { COLLECTIONS } from '../../../config/constants';

export const addVendorCategory = async (id, data) => {
    const docId = data.categoryId;
    return setDoc(doc(db, 'MainEvents', id, 'MyVendorCategory', docId), data, { merge: true });
};

export const addSuggestedVendors = (data,collectionName, id, ...restPath) => {
  return addDoc(
    collection(
        db,
        collectionName, id, ...restPath
      ),
    data
  );
};

export const updateSuggestedVendors = async (data,collectionName, id, ...restPath) => {
  try {
    const events = doc(
      db,
      collectionName, id, ...restPath
    );
    await updateDoc(events, data);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Retrieves a list of vendor categories for a given main event ID.
 * @param {string} mainEventId - The ID of the main event.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of vendor categories.
 */
export const vendorCategoryList = async (mainEventId) => {
    const vendorCategory = await getDocs(
        query(
            collection(db, 'MainEvents', mainEventId, "MyVendorCategory"),
            where('deleted', '==', false),
            where('status', '==', "active"),
            orderBy('sortOrder')
        )
    );
    return vendorCategory.docs.map(doc => { return { ...doc.data(), id: doc.id } })
};

export const updateVendorCategory = async ({ mainId, vendorId, data }) => {
    return updateDoc(doc(db, 'MainEvents', mainId, "MyVendorCategory", vendorId), data);
};

export const getMyVendorCategory = async (mainId, categoryId) => {
    const vendorCategory = await getDocs(
        query(
            collection(db, 'MainEvents', mainId, "MyVendorCategory"),
            where('categoryId', '==', categoryId),
            where('deleted', '==', false),
            where('status', '==', "active"),
            orderBy('sortOrder')
        )
    );
    return vendorCategory.docs.map(doc => { return { ...doc.data(), id: doc.id } })
}

export const getCountOfUnlockedCategory = async ({ mainId }) => {
    try {
        let vendorCategoryList = [];
        const vendorCategory = await getDocs(
            query(
                collection(db, 'MainEvents', mainId, "MyVendorCategory"),
                where('deleted', '==', false),
                where('locked', '==', false), // changed to unlocked categories
                where('status', '==', "active"),
                orderBy('sortOrder')
            )
        );


        vendorCategory.docs.map(doc => {
            vendorCategoryList.push({ ...doc.data(), id: doc.id })
        })

        return vendorCategoryList
    } catch (error) {
        console.log(error);
    }
}

export async function getEventNamesForWhichQuizIsCompleted(
    mainEventId,
    categoryId
  ) {
    try {
      const vendorCategorySnap = await getDocs(
        query(
          collection(
            db,
            COLLECTIONS.MainEvents,
            mainEventId,
            COLLECTIONS.MyVendorCategory,
            categoryId,
            COLLECTIONS.QuizzResponses
          )
        )
      )
      if (vendorCategorySnap.size) {
        const masterEventIds = vendorCategorySnap.docs.map(doc => doc?.id)

        if (masterEventIds.length) {
          const eventSnap = await getDocs(
            collection(db, COLLECTIONS.MasterEvents)
          )
          const events = eventSnap.docs
            .filter(doc => masterEventIds.includes(doc.id))
            .map(doc => doc.data().eventName)
          return events
        }
      }

    } catch (error) {
        console.error('❌ ERROR', error?.message, error)
    }
    return []
  }