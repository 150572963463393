
const ButtonLoader = (props) => {
    return (

        <div className='circleLoader'>
            <div className={`cirecleLoadericon ${props.coloredBorder ? 'orangeBorder' : ''}`}>

            </div>
        </div>
    );
};

export default ButtonLoader;