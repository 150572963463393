import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { loadUserData, signIn } from "./userActions";
import { init } from "./user";

const loadUserDataMiddleware = createListenerMiddleware();

loadUserDataMiddleware.startListening({
  matcher: isAnyOf(signIn.fulfilled, init),
  effect: (_, { dispatch }) => {
    dispatch(loadUserData());
  },
});

export default loadUserDataMiddleware.middleware;
