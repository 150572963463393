import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    budget: 0,
    budgetSplit: [],
    selectedReligion: '',
    isBudgetSaved: false,
    isCountSaved: false,
    budgetConfirmed: false,
    firstPlanningCallScheduled: undefined
};

export const setupBudgetSlice = createSlice({
    name: 'setupBudget',
    initialState,
    reducers: {
        setBudget: (state, action) => {
            let { payload } = action;
            state.budget = payload.budget || 0;
        },
        setBudgetSplitUp: (state, action) => {
            let { payload } = action;
            state.budgetSplit = payload.budgetSplit || [];
        },
        setReligion: (state, action) => {
            let { payload } = action;
            state.selectedReligion = payload.selectedReligion || ''
        },
        setIsDataSaved: (state, action) => {
            let { payload } = action;
            state.isBudgetSaved = payload.isBudgetSaved
            state.isCountSaved = payload.isCountSaved
        },
        setBudgetConfirmed: (state, action) => {
            let { payload } = action;
            state.budgetConfirmed = payload.budgetConfirmed
        },
        setFirstPlanningCallScheduled: (state, action) => {
            let { payload } = action;
            state.firstPlanningCallScheduled = payload.firstPlanningCallScheduled
        },
        setSetupBudgetAll: (state, action) => {
            const keys = [
                "isBudgetSaved",
                "isCountSaved",
                "budgetConfirmed",
                "firstPlanningCallScheduled",
                "budget",
            ]

            keys.forEach(key => {
                if (key in action.payload) {
                    state[key] = action.payload[key]
                }
            })
          },
    }
})

export const {
  setBudget,
  setBudgetSplitUp,
  setReligion,
  setIsDataSaved,
  setBudgetConfirmed,
  setFirstPlanningCallScheduled,
  setSetupBudgetAll,
} = setupBudgetSlice.actions;

export default setupBudgetSlice.reducer