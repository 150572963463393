import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    firstName: "",
    lastName: "",
    phone: "",
    state: "",
    stateId: "",
    religion: "",
    religionId: "",
    brideGroom: "",
    type: "primary",
    photoUrls: "",
    address1: "",
    address2: "",
    city: "",
    cityId: "",
    zipCode: "",
    phoneVerified: false,
    emailVerified: false,
};
export const onBoardingSlice = createSlice({
    name: 'onboardingForm',
    initialState,
    reducers: {
        pageOne: (formData, action) => {
            let { payload } = action;
            if (payload.id) formData.Id = payload.id
            if (payload.firstName) formData.firstName = payload.firstName
            if (payload.lastName) formData.lastName = payload.lastName
            if (payload.phone) formData.phone = payload.phone
            if (payload.state) formData.state = payload.state
            if (payload.stateId) formData.stateId = payload.stateId
            if (payload.religion) formData.religion = payload.religion
            if (payload.religionId) formData.religionId = payload.religionId
            if (payload.brideGroom) formData.brideGroom = payload.brideGroom
            if (payload.type) formData.type = payload.type
            if (payload.photoUrls) formData.photoUrls = payload.photoUrls
            if (payload.address1) formData.address1 = payload.address1
            if (payload.address2) formData.address2 = payload.address2
            if (payload.city) formData.city = payload.city;
            if (payload.cityId) formData.cityId = payload.cityId;
            if (payload.zipCode) formData.zipCode = payload.zipCode;
            if (payload.phoneVerified) formData.phoneVerified = payload.phoneVerified;
            if (payload.emailVerified) formData.emailVerified = payload.emailVerified;
        }
    },
})

export const { pageOne } = onBoardingSlice.actions

export default onBoardingSlice.reducer