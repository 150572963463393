import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cityList: [],
  loading: false,
};

export const citySlice = createSlice({
  name: "citySlice",
  initialState,
  reducers: {
    setLoadingCities: (state) => {
      state.loading = true;
    },
    setCities: (state, action) => {
      let { payload } = action;
      state.cityList = payload?.cities || [];
      state.loading = false;
    },
  },
});

export const { setStates, setCities, setLoadingCities } = citySlice.actions;

export default citySlice.reducer;
