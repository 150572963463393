import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebase/firebase";
import QRCode from 'qrcode';

export const authStateUserData = () => {
    return new Promise((resolve, reject) => {
        try {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    resolve({ user: user, message: 'Sucess' });
                } else {
                    reject({ user: {}, message: 'User is signed out' });
                }
            });

        } catch (error) {
            reject({ user: {}, message: error })
        }

    })
}

export const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*(_:;<>?+=)])[a-zA-Z\d!@#$%^&*(_:;<>?+=)]{8,16}$/;


const qrCodeOpts = {
    errorCorrectionLevel: 'H',
    type: 'image/png',
    quality: 0.3,
    margin: 1,
    color: {
      dark:"#e23b8f",
    }
};

export const generateQrCode = async() => {
    let qr = await QRCode.toDataURL(`${process.env.NEXT_PUBLIC_BASE_URL}Guest-response/InvitationSearch`, qrCodeOpts)
    return qr
}