/**
 * Authentication Wrapper
 *  - a higher order component to check for auth state in private routes
 *  - if not signed in on private routes redirect user to login page
 * @author Emvigo Technologies
 */
import { useAuth } from "./index";
import { isPublicRoute } from "./helperFunctions";
import { useRouter } from "next/router";
import { authStateUserData } from "../../modules/auth";
import { Routes } from '../../config/constants';

const AuthWrapper = ({ children }) => {
    const { pathname, push: redirectTo } = useRouter()

    const { isLoading, user } = useAuth();

    const isPublicPath = isPublicRoute(pathname)
    if (!isPublicPath && !isLoading && !user) {
        authStateUserData().then(({ user: currentUser }) => {
            if (!currentUser) {
                redirectTo(Routes.LOGIN)
            }
        }).catch(() => {
            redirectTo(Routes.LOGIN)
        })

    }
    return children;
};


export default AuthWrapper