import { collection, doc, getDocs, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../../config/firebase/firebase";

export const uploadCustomVendorContract = async (data, id) => {
  try {
    const docRef = doc(db, "Contracts", id);
    await setDoc(docRef, data);
    return true;
  } catch (error) {
    console.log("❌ ", error);
    return false;
  }
}; 
export const getContractList = async ({ categoryId, mainEventId }) => {
  try {
    const contracts = await getDocs(
      query(
        collection(db, 'Contracts'),
        where('deleted', '==', false),
        where('categoryId', '==', categoryId),
        where('mainEventId', '==', mainEventId),
      )
    );
    return contracts.docs.map(doc => { return { ...doc.data(), id: doc.id } })
  } catch (error) {
    return []

  }

};

/**
 * get all the signed contracts with the category
 * @param {string} mainEventId
 */
export async function getSignedContractsByMainEvent(mainEventId) {
  const contracts = await getDocs(
    query(
      collection(db, 'Contracts'),
      where('deleted', '==', false),
      where('contractState', '==', 'contract'),
      where('status', 'in', ['signed', 'active', 'completed']),
      where('mainEventId', '==', mainEventId),
    )
  );
  return contracts.docs.map(doc => { return { ...doc.data(), id: doc.id } })
}

export const getContractListByVendorId = async ({ vendorId, mainEventId }) => {
  const contracts = await getDocs(
    query(
      collection(db, 'Contracts'),
      where('deleted', '==', false),
      where('vendorId', '==', vendorId),
      where('mainEventId', '==', mainEventId),
    )
  );
  return contracts.docs.map(doc => { return { ...doc.data(), id: doc.id } })
};

export const getContractListByCategoryId = async ({
  categoryId,
  vendorId,
  mainEventId,
}) => {
  const contracts = await getDocs(
    query(
      collection(db, "Contracts"),
      where("deleted", "==", false),
      where("vendorId", "==", vendorId),
      where("categoryId", "==", categoryId),
      where("mainEventId", "==", mainEventId),
      where("contractType", "==", "dwContract"),
      where("status", "in", ["signed", "active", "completed", "draft"])
    )
  );
  let array = [];

  contracts.docs.map((doc) => {
    array.push(...doc.data().events);
  });
  return array;
};

export const updateCustomVendorContract = async ({ contractId, data }) => {
  return updateDoc(doc(db, 'Contracts', contractId), data);
};

export const getAllContracts = async ({ mainEventId }) => {
  const contracts = await getDocs(
    query(
      collection(db, 'Contracts'),
      where('deleted', '==', false),
      where('mainEventId', '==', mainEventId),
      orderBy("updatedAt", "desc")
    )
  );
  return contracts.docs.map(doc => { return { ...doc.data(), id: doc.id } })
};

export const getCompletedContractList = async ({
  categoryId,
  mainEventId,
}) => {
  const contracts = await getDocs(
    query(
      collection(db, "Contracts"),
      where("deleted", "==", false),
      where("categoryId", "==", categoryId),
      where("mainEventId", "==", mainEventId),
      where("status", "in", ["signed","completed"])
    )
  );
  return contracts.docs.map(doc => { return { ...doc.data() } })

};

export const getPendingProposalList = async ({
  categoryId,
  mainEventId,
  vendorId
}) => {
  const contracts = await getDocs(
    query(
      collection(db, "Contracts"),
      where("deleted", "==", false),
      where("categoryId", "==", categoryId),
      where("mainEventId", "==", mainEventId),
      where("vendorId", "==", vendorId),
      where("status", "in", ["draft"])
    )
  );
  return contracts.docs.map(doc => { return { ...doc.data() } })

};
