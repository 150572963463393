import routeConfig from "./routeConfig.json"
const { publicRoutes, privateModules,freeModules } = routeConfig

/**
 * Check whether a path is a public path or private path
 * @param path
 * @returns {boolean}
 */
export const isPublicRoute = (path = "/") => {
    if (path === "/") {
        return true
    }
    return publicRoutes.some(route => path.includes(route))
}

/**
 * Check whether a path belongs to private module
 * If yes returns module details
 * If no match returns null
 * @param path
 * @returns {Object}
 */
export const getPrivateModuleByRoute = (path = "/") => {
    const moduleInfo = privateModules.find(module => module.path.includes(path))
    if (!moduleInfo) {
        return null
    }
    return moduleInfo
}

/**
 * Check whether a path is a free module path
 * @param path
 * @returns {boolean}
 */
export const isFreeModule = (path = "/") => {
    if (path === "/") {
        return true
    }
    return freeModules.some(route => path.includes(route))
}