import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    levelOneFormId: ''
};

export const setFormIdSlice = createSlice({
    name: 'setupFormId',
    initialState,
    reducers: {
        setLevelOneFormId: (state, action) => {
            let { payload } = action;
            state.levelOneFormId = payload.levelOneFormId || [];
        }
    }
})

export const { setLevelOneFormId } = setFormIdSlice.actions

export default setFormIdSlice.reducer