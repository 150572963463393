import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  salutation: "",
  relationship: "",
  streetAddress: "",
  state: "",
  stateId: "",
  city: "",
  cityId: "",
  zipCode: "",
  flightReq: false,
  hotelReq: false,
  transportationReq: false,
  groupId: ""
};
export const addGuestSlice = createSlice({
    name: 'addGuestForm',
    initialState,
    reducers: {
        guestPageOne: (formData, action) => {
            let { payload } = action;
            if (payload.id) formData.Id = payload.id
            if (payload.salutation) formData.salutation = payload.salutation
            if (payload.relationship) formData.relationship = payload.relationship
            if (payload.streetAddress) formData.streetAddress = payload.streetAddress
            if (payload.state) formData.state = payload.state
            if (payload.stateId) formData.stateId = payload.stateId
            if (payload.city) formData.city = payload.city;
            if (payload.cityId) formData.cityId = payload.cityId;
            if (payload.zipCode) formData.zipCode = payload.zipCode;
            if (payload.flightReq) formData.flightReq = payload.flightReq;
            if (payload.hotelReq) formData.hotelReq = payload.hotelReq;
            if (payload.transportationReq) formData.transportationReq = payload.transportationReq;

        }
    },
})

export const { guestPageOne } = addGuestSlice.actions

export default addGuestSlice.reducer