import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserByEmail } from '../../models/users';
import { getMainEventDetails } from '../../models/MainEvents/mainEvents';
import { getDocumentById } from '../../models';
import { authStateUserData } from '../../modules/auth';

export const loggedInUserDetails = createAsyncThunk(
  'user/details',
  async ({ email ,MainEventDetails}, { rejectWithValue }) => {
    try {
      if (!email) {
        const { user } = await authStateUserData()
        email = user.email
      }

      if (!email) return rejectWithValue({ code: 'USER_NOT_FOUND', message: 'Unable to fetch user without email' });

      const userDetails = await getUserByEmail(email);
      const mainEventDetails = MainEventDetails||await getMainEventDetails({
        value: userDetails.id,
        field: userDetails.brideGroom === 'Bride' ? 'brideId' : 'groomId',
      });

      const brideGroomId = userDetails.brideGroom === 'Bride' ? mainEventDetails.groomId : mainEventDetails.brideId
      const partnerDetails = await (brideGroomId ? getDocumentById("Users", brideGroomId) : {})

      let userData = {
        email: userDetails.email,
        phone: userDetails.phone,
        address1: userDetails.address1,
        address2: userDetails.address2,
        city: userDetails.city,
        cityId: userDetails.cityId,
        state: userDetails.state,
        zipCode: userDetails.zipCode,
        photoUrls: userDetails.photoUrls,
        id: userDetails.id,
        userType: userDetails.brideGroom,
        brideGroom: userDetails.brideGroom,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        name: userDetails.firstName + ' ' + userDetails.lastName,
        religion: userDetails.religion,
        religionId: userDetails.religionId,
        BnGReligions: [userDetails.religion],
        phoneNumberVerified: userDetails.phoneNumberVerified,
        sameReligion: true,
        BnGReligionIds: [userDetails.religionId],
        stateId: userDetails?.stateId,
        regDate: userDetails.createdAt?.toDate()?.toISOString(),
        BnGName: userDetails.firstName,
        date: mainEventDetails.date?.toDate()?.toISOString(),
        MainEventId: mainEventDetails.id,
        MainEventDetails: mainEventDetails,
        backgroundImage: mainEventDetails.backgroundImage,
        dateConfirmed: mainEventDetails.dateConfirmed,
        selectedReligion: mainEventDetails.selectedReligion,
        partnerEmail: partnerDetails.email,
        partnerName: partnerDetails.firstName || "" + ' ' + partnerDetails.lastName || "",
        partnerFirstName: partnerDetails.firstName,
        partnerLastName: partnerDetails.lastName,
        partnerPhone: partnerDetails.phone,
        partnerReligion: partnerDetails.religion,
        partnerReligionId: partnerDetails.religionId,

        guestCount: mainEventDetails.expectedGuestCount,
        budget: mainEventDetails?.budgetPlan?.totalBudget || 0,
        SubscriptionPlan: mainEventDetails?.subscription,
        weddingInterval: mainEventDetails?.weddingInterval,
        signup_with: mainEventDetails?.signup_with,
        subscriptionDate: mainEventDetails?.subscription?.subscribedOn?.toDate()?.toISOString() || mainEventDetails?.subscription?.lastUpdatedOn?.toDate()?.toISOString(),
      }

      if (partnerDetails && partnerDetails.religion) {
        userData.sameReligion = partnerDetails.religion === userDetails.religion
        userData.BnGReligions.push(partnerDetails.religion)
        userData.BnGReligionIds.push(partnerDetails.religionId)
        userData.BnGName = `${userDetails.firstName} & ${partnerDetails.firstName}`
        userData.partnerName = partnerDetails.firstName
        userData.partnerId = partnerDetails.id
      }

      return userData
    } catch (error) {
      return rejectWithValue({ code: error.code, message: error.message });
    }
  }
);
