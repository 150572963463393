import { del, post } from "../../req";
const apiUrl = process.env.NEXT_PUBLIC_API_URL;

export const addTodos = async (reqBody) => {
    try {
        await post(`${apiUrl}/todo`, reqBody)
        return { status: true }
    } catch (error) {
        return { status: false }
    }
}

export const deletePlayerID = async (userId, playerId) => {
    try {
        await post(
            `${apiUrl}/notification/playerid/delete`,
            { userType: 'pwa', userid: userId, playerid: playerId },
            false,
            true
        );
        return true;
    } catch (error) {
        return false;
    }
}

export const addPlayerID = async (userId, playerId) => {
    try {
        let player = await post(`${apiUrl}/notification/playerid`, { userType: 'pwa', userid: userId, playerid: playerId });
        return player;
    } catch (error) {
        console.log(error);
        throw new Error("Failed to add playerid");
    }
}

export const sendEmailNotification = async (reqBody) => {
    try {
        await post(`${apiUrl}/notification/email`, reqBody)
        return { status: true }
    } catch (error) {
        return { status: false }
    }
}

export async function promptNotificationWindow(userId) {
    try {
        console.log(`🚀 OneSignal - PUSH promptNotificationWindow called: "${userId}"`)
        const playerId = await OneSignal.getUserId();
        console.log(`🚀 OneSignal - PUSH playerId: "${playerId}"`, )

        const handler = (isSub) => {
            console.log(`🚀 OneSignal - PUSH handler: "${isSub}"`)
            if (isSub) {
                addPlayerID(userId, playerId);
            } else {
                deletePlayerID(userId, playerId);
            }
        };

        handler(await OneSignal.getSubscription().catch(err => console.error('❌ OneSignal.getSubscription ', err)));

        OneSignal.off("subscriptionChange", handler);
        OneSignal.on("subscriptionChange", handler);
    } catch (error) {
        console.log(error);
    }
}

export async function cleanUpOneSignalUser() {
    try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const id = await OneSignal.getUserId();
        await deletePlayerID(userData.id, id);
    } catch (error) {
        console.log(error);
    }
}