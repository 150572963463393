/* eslint-disable @next/next/no-img-element */
import Logo from "../../../components/Logo/LogoNew";
const splash = () => {
  return (
    <div className="splash-wrp gradientBg">
      <Logo />
      <div className="splashBottom">
        {/* <figure>
          {" "}
          <img src="/images/splash-fig.svg" alt="splash" />
        </figure> */}
        <div className="bottomText">
          <h4>Indian Wedding Planning</h4>
          <h5>on your terms</h5>
        </div>
      </div>

    </div>
  );
};

export default splash;
