
import {getDocumentById} from "../index";

/**
 * Load main event details and permissions of the user
 * @param userInfo
 * @returns {Promise<Object>}
 */
export const getUserPermissions = (userInfo) => {
    return new Promise(async (resolve, reject) => {
        try {
            const {mainEventId}=userInfo
            const [mainEvent,permissions]=await Promise.all([
                getDocumentById("MainEvents",mainEventId),
                getDocumentById(`MainEvents/${mainEventId}/permissions`,mainEventId)
            ])
            resolve({mainEvent,permissions})
        }catch (e) {
            reject(e)
        }
    })
}