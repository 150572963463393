import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    eventList: [],
};

export const VendorContractSlice = createSlice({
    name: 'vendorContractDetails',
    initialState,
    reducers: {
        setContractEventList: (state, action) => {
            let { payload } = action;
            state.eventList = payload.eventList || [];
        },
    }
})

export const { setContractEventList } = VendorContractSlice.actions

export default VendorContractSlice.reducer