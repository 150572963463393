
import Link from "next/link";
import Logo from "../../components/Logo/Logo";
import PageTitle from "../../components/PageTitle";
import { useAuth } from "../AuthContext";
import { _billingDateFormat } from "../Cards/SubscriptionPaymentCard";
import ErrorPopUp from "../Popup/ErrorPopUp";
import { useRouter } from "next/router";

const UpgradeRequired = () => {
    const router = useRouter()
    const { mainEvent } = useAuth();
    const subscriptionStatus = mainEvent?.subscription?.status || "canceled"
    const lastUpdatedOn = _billingDateFormat(mainEvent?.subscription?.lastUpdatedOn?.toDate().toDateString() || new Date())
    return (
        <>
            <PageTitle>Subscribe</PageTitle>
            <div className="landing-wrp gradientBg Upgrade">
                <div className="landingWrap">
                    <Logo />
                    <h4>Upgrade your plan to access this feature</h4>
                    <p>
                        On {lastUpdatedOn}, your subscription was {subscriptionStatus}.

                        Get access to DW features by subscribing.
                    </p>
                </div>

                <div className="loginBtn-wrp">
                    <Link href="/ManageSubscription/Plans">
                        <button className="bgBtn">Okay</button>
                    </Link>
                </div>
                <ErrorPopUp
                    head="Subscribe"
                    body={`On ${lastUpdatedOn}, your subscription was ${subscriptionStatus}.
                        Get access to DW features by subscribing.`}
                    buttonContent="Okay"
                    buttonActiom={() => router.push('/ManageSubscription/Plans')} />
            </div>
        </>
    );
};

export default UpgradeRequired;
