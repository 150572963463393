import { Avatar, useChannelStateContext } from "stream-chat-react";
import { useChatClient } from "../../hooks/useConnectUser";
import { useRouter } from "next/router";

export default function ChatPopupHeader({ onMinimize, minimized }) {
    const { channel, members } = useChannelStateContext();
    const { setActiveChannel } = useChatClient();
    const router = useRouter();

    const name = channel?.data?.pwa_name || channel?.data?.name || "";
    const memberCount = channel?.data?.member_count || 0;

    const onlineCount = Object
        .values(members)
        .filter(({ user }) => user?.online)?.length || 0;

    const image = channel?.data?.image || "";

    function expand() {
        router.push(`/Chat/${channel.id}`);
    }

    function close() {
        setActiveChannel(null);
    }

    return (

        <div className="chat-popup-header">
            {/* <Avatar image={image} /> */}
            <Avatar name={name} image={image} />
            <div className="chat-popup-user-name"> {name} <p className="str-chat__header-livestream-left--members str-chat__channel-header-info">
                {memberCount} members, {onlineCount} online
            </p></div>
            <div className="chat-action-btns">
                <button onClick={expand} className="chat-expand"><img alt="" src="/images/chat-expand.svg"></img></button>
                <button onClick={onMinimize} className={`${minimized ? "minimized" : ""}`}><img alt="" src="/images/chat-down.svg"></img></button>
                <button onClick={close}><img alt="" src="/images/chat-close.svg"></img></button>
            </div>
        </div>

    );
}
