import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDocumentById } from '../../models';
import { getAllContracts } from '../../models/contracts/contracts';
import { vendorCategoryList } from '../../models/MainEvents/vendorCategories/vendorCategories';

export const getContractList = createAsyncThunk(
  'contract/details',
  async ({ mainEventId }, { rejectWithValue }) => {
    try {
      let vendorCategories = await vendorCategoryList(mainEventId)
      let list = await getAllContracts({ mainEventId: mainEventId })

      let contractList = {};
      let count = { completed: 0, requested: 0 }
      if (list && list.length > 0) {
        for (const data of list) {
          let category = vendorCategories.find(cat => cat.categoryId === data.categoryId)
          const VendorDetails = await getDocumentById(data.contractType === 'selfManged' ? 'SelfManagedVendor' : 'Vendors', data.vendorId)
          data.createdAt = data.createdAt?.toDate().toISOString()
          data.updatedAt = data.updatedAt?.toDate().toISOString()
          delete data.updatedBy
          delete data.religion
          delete data.religionId
          delete data.deleted

          if (contractList[category.sortOrder] === undefined) {
            contractList[category.sortOrder] = { completed: [], requested: [], categoryName: category.categoryName };
          }

          if (data.status === 'completed' || data.status === 'signed') {
            contractList[category.sortOrder].completed.push({ ...data, vendorDetails: VendorDetails });
            count.completed += 1
          } else {
            contractList[category.sortOrder].requested.push({ ...data, vendorDetails: VendorDetails });
            count.requested += 1
          }

        }
      }

      return { contractList, count }
    } catch (error) {
      return rejectWithValue({ code: error.code, message: error.message });
    }
  }
);
