import React, { useEffect, useState } from 'react';
import { Chat, Channel, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';
import ChatPopupHeader from './ChatPopupHeader';
import { useChatClient } from '../../hooks/useConnectUser';
import { useRouter } from 'next/router';

const ALLOWED_CHAT_MESSAGE_ACTIONS = [
  'delete',
  'edit',
  'flag',
  'pin',
  'quote',
  'react'
];

export default function ChatPopup({ channelId }) {
  const { client } = useChatClient();
  const [channel, setChannel] = useState();
  const [isMinimize, setIsMinimize] = useState(false)
  const router = useRouter();

  useEffect(() => {
    if (client && channelId) {
      async function initChannel() {
        try {
          const channel = client.channel("contract_intimation", channelId);
          await channel.watch();
          setChannel(channel);
        } catch (error) {
          console.log(error);
        }
      }

      initChannel();
    }
  }, [client, channelId]);

  function minimize() {
    setIsMinimize(!isMinimize);
  }

  if (router.pathname.startsWith("/Chat") || !channel) {
    return (<></>);
  }

  return (
    <>
      {(client?.user?.id && channelId) && <div className={`chat-popup open ${isMinimize ? "minimize" : ""}`}>
        <div className="chat-popup-body">
          <Chat client={client} theme='str-chat__theme-light messaging'>
            <Channel channel={channel}>
              <Window>
                <ChatPopupHeader onMinimize={minimize} minimized={isMinimize} />
                <MessageList messageActions={ALLOWED_CHAT_MESSAGE_ACTIONS} />
                <MessageInput focus />
              </Window>
              <Thread />
            </Channel>
          </Chat>
        </div>
      </div>}
    </>
  );
}