import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../config/firebase/firebase";

export const getVendorCategories = async (religionId) => {
    const categories = [];
    try {
        const snapshot = (await getDocs(
            query(
                collection(db, "VendorCategories"),
                where("deleted", "==", false),
                where("status", "==", "active"),
                where(`religion.${religionId}.active`, '==', true)
            ),
        )
        );
        snapshot.forEach(function (doc) {
            categories.push(
                { ...doc.data(), id: doc.id }
            );
        });
        return (categories);
    } catch (error) {
        return []
    }
}

export const getBudgetCategories = async (religionId) => {
    const categories = [];
    try {
        const snapshot = (await getDocs(
            query(
                collection(db, 'Religions', religionId, "BudgetCategories"),
                where("deleted", "==", false),
                where("status", "==", "active"),
                orderBy("name")
            ),
        )
        );
        snapshot.forEach(function (doc) {
            categories.push(
                { ...doc.data(), id: doc.id }
            );
        });
        return (categories);
    } catch (error) {
        return []
    }
}
