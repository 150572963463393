import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../config/firebase/firebase";
export const getUserByEmail = async (email) => {
    try {
        const snapshot = (await getDocs(
            query(
                collection(db, "Users"),
                where("email", "==", email),
                where("status", "==", "active")
            ),
        )
        );
        let doc = (snapshot)?.docs[0]
        return { ...doc?.data(), id: doc.id }

    } catch (error) {
        return {}
    }
}

export const getUserByUpdatedEmail = async (email) => {
    const posts = [];
    try {
        const snapshot = (await getDocs(
            query(
                collection(db, "Users"),
                where("updatedBy", "==", email),
                where("status", "==", "active")
            ),
        )
        );
        snapshot.forEach(function (doc) {
            posts.push(
                { ...doc.data(), id: doc.id }
            );
        });
        return (posts);
    } catch (error) {
    }
}

export const getMainEventId = async (email) => {
    try {
        const snapshot = (await getDocs(
            query(
                collection(db, "Users"),
                where("email", "==", email),
                where("status", "==", "active")
            ),
        )
        );
        let doc = (snapshot)?.docs[0]
        return doc?.data()?.mainEventId
    } catch (error) {
    }
}
export const getUsersByMainEventId = async (mainEventId) => {
    const posts = [];
    try {
        const snapshot = (await getDocs(
            query(
                collection(db, "Users"),
                where("mainEventId", "==", mainEventId),
                where("status", "==", "active")
            ),
        )
        );
        snapshot.forEach(function (doc) {
            posts.push(
                { ...doc.data(), id: doc.id }
            );
        });
        return (posts);
    } catch (error) {
    }
}


