import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { firebaseConfig } from "./firebaseConfig";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = initializeFirestore(app, {
  experimentalForceLongPolling: process.env.NEXT_PUBLIC_ENV === "uat",
});
export const storage = getStorage(app);
export const analytics = () => getAnalytics(app);

export const WhereOps = {
  EQUALS: "==",
  CONTAINS: "array-contains",
};
