import { createSlice } from '@reduxjs/toolkit';
import { getContractList } from './contractActions';

const initialState = {
    contractList: [],
    count: {},
    contractDetail: {}
};

export const setupContractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        setContractList: (state, action) => {
            let { payload } = action;
            state.contractList = payload.contracts || [];
        },
        setContractDetail: (state, action) => {
            let { payload } = action;
            state.contractDetail = payload.contractDetails || {};
        }
    },
    extraReducers: {
        [getContractList.fulfilled]: (state, action) => {
            let { contractList, count } = action.payload;
            state.contractList = contractList ?? [];
            state.count = count;
        },
    },
})

export const { setContractList, setContractDetail } = setupContractSlice.actions

export default setupContractSlice.reducer