import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    email: "",
    password: "",
    code: "",
    token: "",
};

export const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {
        sendEmail: (state, action) => {
            let { payload } = action;
            state.email = payload.email || "";
        },
        verifyCode: (state, action) => {
            let { payload } = action;
            state.code = payload.code || "";

        }
    }
})

export const { sendEmail, verifyCode } = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer