import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loadUserDataMiddleware from "./authentications/loadUserDataMiddleware";
import forgotPassword from "./authentications/forgotPassword";
import onBoarding from "./authentications/onboardScreen";
import user from "./authentications/user";
import setupBudget from "./budget/setupBudget";
import loggedInUser from "./loggedInUser/loggedInUser";
import todoDetails from "./todo/todo";
import dashboard from "./dashboard/dashboard";
import vendor from "./vendor/vendor";
import uploadVendorDetails from "./vendor/uploadVendorDetails";
import setupFormId from "./typeForm/setFormId";
import vendorContractDetails from "./vendor/vendorContractDetails";
import template from "./template/template";
import guestOne from "./guest/addGuest";
import payment from "./payment/payment";
import contract from "./contract/contract";
import notification from "./notification/setNotification";
import cities from "./city/city";

const combineReducer = combineReducers({
  onBoarding,
  user,
  forgotPassword,
  setupBudget,
  loggedInUser,
  todoDetails,
  dashboard,
  setupFormId,
  vendor,
  uploadVendorDetails,
  vendorContractDetails,
  template,
  guestOne,
  payment,
  contract,
  notification,
  cities,
});

const rootReducer = (state, action) => combineReducer(state, action);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(
      loadUserDataMiddleware
    ),
});

export default store;
