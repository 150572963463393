const ErrorPopUp = ({ head, body, buttonContent, buttonActiom, closeButton, showCloseButton = false }) => {

    return (
        <div className="overlay show Vendor-Unlock">
            <div className="popup">
                {showCloseButton &&
                    <div className='modal-header'>
                        <button className="closeBtn cursorPointer" onClick={closeButton || buttonActiom}> </button>
                    </div>
                }
                <h4>
                    {head}
                </h4>
                <p>
                    {body}
                </p>
                {buttonContent && <button className="bgBtn cursorPointer" onClick={buttonActiom}>{buttonContent}</button>}
            </div>
        </div>
    )
}
export default ErrorPopUp;