import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    type: '',
    category: '',
    vendor: '',
    totalAmount: '',
    payAmount: '',
    description: ''
};

export const setupPaymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setPaymentDetails: (state, action) => {
            let { payload } = action;
            state.type = payload.type || '';
            state.category = payload.category || '';
            state.vendor = payload.vendor || '';
            state.totalAmount = payload.totalAmount || '';
            state.payAmount = payload.payAmount || '';
            state.description = payload.description || '';
        },
    },
})

export const { setPaymentDetails } = setupPaymentSlice.actions

export default setupPaymentSlice.reducer