import { createSlice } from '@reduxjs/toolkit';
import { loggedInUserDetails } from './userActions';

const initialState = {
  user: {},
  partnerText: '',
};

export const loggedInUserSlice = createSlice({
  name: 'loggedInUser',
  initialState,
  reducers: {
    reset: (state) => {
      state.user = {};
    },
    setPartnerText: (state, action) => {
      state.partnerText = action.payload;
    },

  },
  extraReducers: {
    [loggedInUserDetails.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { reset,setPartnerText } = loggedInUserSlice.actions;

export default loggedInUserSlice.reducer;
