import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { createGroupFor, createToken } from '../models/chat/apis';
import { useAuth } from '../components/AuthContext';
import { StreamChat } from 'stream-chat';
import PropTypes from "prop-types";
import { useRouter } from 'next/router';
import useBreakpoint from 'use-breakpoint'
import ChatPopup from '../components/Chat/ChatPopUp';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }
const STREAM_ID = process.env.NEXT_PUBLIC_STREAM_ID;

const tokenProvider = async () => {
    const token = await createToken();
    return token.token;
}

function encodeEmail(email) {
    return email ? Buffer.from(email).toString('base64').replace(/\W+/g, '') : "";
}

/**
 * A hook which handles the process of connecting/disconnecting a user
 * to the Stream Chat backend.
 *
 * @return {[import("stream-chat").StreamChat<import("stream-chat").DefaultStreamChatGenerics> | null]}
 */
export const useConnectCurrentUser = () => {
    const { user } = useAuth();
    const userEmail = useMemo(() => user?.email, [user]);
    const [chatClient, setChatClient] = useState(null);

    useEffect(() => {
        if (!userEmail) return;

        const client = StreamChat.getInstance(STREAM_ID);
        let didUserConnectInterrupt = false;

        const connectUser = client
            .connectUser({ id: encodeEmail(userEmail) }, tokenProvider)
            .catch((e) => {
                console.error(`Failed to connect user`, e);
            })
            .then((v) => {
                if (!didUserConnectInterrupt) {
                    setChatClient(client);
                }
            });

        return () => {
            didUserConnectInterrupt = true;

            connectUser.then(() => {
                setChatClient(null);
                client.disconnectUser()
                    .catch((e) => {
                        console.error(`Failed to disconnect user`, e);
                    });
            });
        };
    }, [userEmail]);

    return chatClient;
};

const ChatContext = createContext()

/**
 * 
 * @return {StreamChat}
 */
export function useChatClient() {
    return useContext(ChatContext);
}

export function useCreateGroup() {
    const { setActiveChannel } = useChatClient();
    const { breakpoint } = useBreakpoint(BREAKPOINTS);
    const router = useRouter();
    const [loading, setLoading] = useState(false);

    function create(vendorId, onComplete) {
        setLoading(true);
        
        createGroupFor(vendorId)
            .then(async ({ body }) => {
                const { id } = body.data;
                if (breakpoint !== "mobile") {
                    setActiveChannel(id);
                } else {
                    router.push(`/Chat/${id}`);
                }
            })
            .finally(() => {
                setLoading(false);
                onComplete && onComplete();
            });
    }

    return { loading, createChatGroup: create };
}


export function ChatProvider({ children }) {
    const client = useConnectCurrentUser();
    const router = useRouter();
    const [activeChannel, setActiveChannel] = useState();
    const isChatPath = router.pathname.startsWith("/Chat");
    const channelId = router.query.channel;

    useEffect(() => {
        if (!isChatPath && channelId) {
            setActiveChannel(channelId);
        }
    }, [isChatPath, router.pathname, channelId]);

    return (
        <ChatContext.Provider value={{ client, setActiveChannel }}>
            {children}
            <ChatPopup channelId={activeChannel} />
        </ChatContext.Provider>
    );
}

ChatProvider.propTypes = {
    children: PropTypes.node
}