import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "../../config/firebase/firebase";
import { createUser, signInUser, signOutUser } from "../../lib/firebaseAuth";
import { getUserByEmail } from "../../models/users";
import { getMainEventDetails } from '../../models/MainEvents/mainEvents';
import { promptNotificationWindow } from "../../modules/notification/notifications";

export const signUp = createAsyncThunk(
    "user/signUp",
    async ({ email="", password="",signUpType }, { rejectWithValue }) => {
        try {
            let { user } = await createUser(email, password,signUpType);
            console.log("user",user);
            promptNotificationWindow(user.uid);
            return { email: user.email, idToken: user.accessToken, uid: user.uid ,displayName:user?.displayName||"",photoURL:user?.photoURL||"",emailVerified:user?.emailVerified||false};
        } catch (error) {
            console.log("error",error);
            return rejectWithValue({ code: error.code, message: error.message });
        }
    }
)
export const signIn = createAsyncThunk(
    "user/signIn",
    async ({ email, password }, { rejectWithValue }) => {
        try {
            let { user } = await signInUser(email, password);
            const userDetails = await getUserByEmail(user.email);
            const mainEventDetails = await getMainEventDetails({
                value: userDetails.id,
                field: userDetails.brideGroom === 'Bride' ? 'brideId' : 'groomId',
            });
            let userData = {
                id: userDetails.id,
                userType: userDetails.brideGroom,
                religion: userDetails.religion,
                MainEventId: mainEventDetails.id,
            }
            localStorage.setItem("userData", JSON.stringify(userData));
            promptNotificationWindow(userDetails.id);
            return { email: user.email, idToken: user.accessToken, uid: user.uid };
        } catch (error) {
            return rejectWithValue({ code: error.code, message: error.message });
        }
    }
)


export const signOut = createAsyncThunk(
    "user/signOut",
    async () => {
        try {
            localStorage.removeItem("userData");
            await signOutUser();
            return true
        } catch (error) {
            return rejectWithValue({ code: error.code, message: error.message });
        }
    }
)

export const loadUserData = createAsyncThunk(
    "user/loadUserData",
    async () => {
        try {
            const { email, uid } = auth.currentUser;
            if (email && uid) {

                const response = await getUserByEmail(email);
                return { ...response, uid }
            } else {
                return {}
            }
        } catch (error) {
            return rejectWithValue({ code: error.code || 'SOMETHING_WENT_WRONG', message: error.message || "Something went wrong" });
        }
    }
);