import { serverTimestamp } from "firebase/firestore";
import { getDocumentById } from "../../../../models";
import { getContractList } from "../../../../models/contracts/contracts";
import { addVendorCategory, vendorCategoryList } from "../../../../models/MainEvents/vendorCategories/vendorCategories";
import { getVendorCategories } from "../../../../models/vendorCategories/vendorCategories";

export const getVendorCategoryList = async (religionId) => {
    try {
        let list = await getVendorCategories(religionId)
        return list ? list : []
    } catch (error) {
        return [];
    }
};

export const addVendorCategories = (id, categoryList) => {
    try {
        return Promise.all(categoryList.map(data => addVendorCategory(id,
            {
                categoryId: data.id,
                locked: data.name !== "Venues",
                vendorAssigned: false,
                deleted: false,
                status: "active",
                sortOrder: data.sortOrder ?? 99,
                categoryName: data.name,
                needPersonalityQuiz: data.needPersonalityQuiz,
                needGlamQuiz: data?.needGlamQuiz,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            })));
    } catch (error) {
        console.error('❌ ERROR', error)
        return [];
    }
};

export const userSpecificVendorCategory = async (id) => {
    try {
        const list = await vendorCategoryList(id)

        const categoryDetails = []

        async function getData(data) {
          const { categoryId } = data;
          const [details, contractDetails] = await Promise.all([
            getDocumentById("VendorCategories", categoryId),
            getContractList({ categoryId: categoryId, mainEventId: id }),
          ]);
          categoryDetails.push({ ...data, details, contractDetails });
        }

        const promises = []

        for (const data of list) {
            promises.push(getData(data))
        }

        await Promise.all(promises)

        return categoryDetails
    } catch (error) {
        return [];
    }
};