import { createSlice } from '@reduxjs/toolkit';
import { getTodoDetails } from './todoActions';

const initialState = {
    todoDetails: {},
    updateTodoDetails: {}
};

export const setupTodoSlice = createSlice({
    name: 'todoDetails',
    initialState,
    reducers: {
        setTodoDetails: (state, action) => {
            let { payload } = action;
            state.todoDetails = payload.todoDetails || {};
        },
        updateTodoDetails: (state, action) => {
            let { payload } = action;
            state.updateTodoDetails = payload.updateTodoDetails || {};
        }
    },
    extraReducers: {
        [getTodoDetails.fulfilled]: (state, action) => {
            state.todoDetails = action.payload;
        },
    },
})

export const { setTodoDetails, updateTodoDetails } = setupTodoSlice.actions

export default setupTodoSlice.reducer