import { useCallback } from "react";
import Link from "next/link";
import Logo from "../../components/Logo/Logo";
import PageTitle from "../../components/PageTitle";
import Button from "../Common/Button";
import {useRouter} from "next/router";
import { Routes } from '../../config/constants';

const PermissionDenied = () => {
    const router= useRouter()

    const routeRedirect = useCallback(() => router.push(Routes.DASHBOARD), []);

    return (
        <>
            <PageTitle>Access Denied</PageTitle>
            <div className="landing-wrp gradientBg Upgrade">
                <div className="landingWrap">
                    <Logo />
                    <h4>Access Denied</h4>
                    <p>
                        Upgrade your plan to access this feature
                    </p>
                </div>

                <div className="loginBtn-wrp">
                    <Link href="/ManageSubscription/Plans">
                        <button className="bgBtn m-10">Upgrade</button>
                    </Link>
                    <Link href={Routes.DASHBOARD}>
                        <button className="wbgBtn">Back to Dashboard</button>
                    </Link>
                </div>

                <div className="veryfied overlay show">
                    <div className={`popup `}>
                        <h4>
                            Access Denied
                        </h4>
                        <p>
                            Upgrade your plan to access this feature
                        </p>
                        <div className="updateBtn ">
                            <Button
                                onClick={() => router.push('/ManageSubscription/Plans')}
                                className="bgBtn Mt-0">
                                Upgrade
                            </Button>

                            <Button
                                onClick={routeRedirect}
                                className="wbgBtn">
                                Back to Dashboard
                            </Button>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};

export default PermissionDenied;
