import ButtonLoader from "../Loader/ButtonLoader";

/**
 * button with common features
 *  * loading with ButtonLoader
 * 
 * @param {import("react").ButtonHTMLAttributes & { loading: boolean }} props 
 */
export default function Button({ loaderWithText = false, ...props }) {
    const { children, loading, ...other } = props;
    const disabled = props.disabled || loading;

    return (
        <button disabled={disabled} {...other}>
            {(loading && loaderWithText || !loading) && children}
            {loading && <ButtonLoader />}
        </button>
    );
}