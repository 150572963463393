import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    templateList: [],
    filterTagList: [],
    templateDetails: {}
};

export const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        setTemplate: (state, action) => {
            let { payload } = action;
            state.templateList = payload.list || [];
        },
        setFilterTagList: (state, action) => {
            let { payload } = action;
            state.filterTagList = payload.list || [];
        },
        setTemplateDetails: (state, action) => {
            let { payload } = action;
            state.templateDetails = payload.templateDetails || {};
        }
    }
})

export const { setTemplate, setFilterTagList, setTemplateDetails } = templateSlice.actions

export default templateSlice.reducer