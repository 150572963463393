import { addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, updateDoc, where, writeBatch, arrayUnion } from "firebase/firestore";
import { db } from "../../config/firebase/firebase";

export const updateMainEventsDetails = async (data, id) => {
  try {
    let getQuery = doc(db, "MainEvents", id);
    await updateDoc(getQuery, data);
    if (data.dateConfirmed) {
      updateAllContracts(id, data.date)
    }
    return true
  } catch (error) {
    return false
  }
}
const updateAllContracts = async (id, date) => {
  const batch = writeBatch(db);
  const contracts = await getDocs(
    query(
      collection(db, 'Contracts'),
      where('mainEventId', '==', id)
    )
  );
  contracts.docs?.map(snapshot => batch.update(snapshot.ref, { "weddingDate": date }))
  await batch.commit();
}

export const getMainEventDetails = async ({ value, field }) => {
  const mainEventQuery = query(
    collection(db, 'MainEvents'),
    where(field, '==', value),
    where('status', '==', 'active')
  );

  const mainEventSnapshot = await getDocs(mainEventQuery);

  let data = {};

  const subcollectionNames = ['MyVendorCategory', 'MyEvents','Guests'];

  for (const doc of mainEventSnapshot.docs) {
    const subcollectionData = await Promise.all(subcollectionNames.map(async (subcollectionName) => {
      const subcollectionRef = collection(doc.ref, subcollectionName);
      const subcollectionSnapshot = await getDocs(subcollectionRef);
      return subcollectionSnapshot.docs.map(doc => doc.data());
    }));

    subcollectionNames.forEach((name, index) => {
      data[name] = subcollectionData[index];
    });

    data = {
      id: doc.id,
      ...doc.data(),
      ...data
    };
  }

  return data;
};


/**
 * get main event doc
 * @param {string} id
 * @returns {Promise<any>}
 */
export const getMainEvent = async (id) => {
  try {
    const mainEvent = await getDoc(
      doc(db, "MainEvents", id)
    );

    return { ...mainEvent.data(), uid: mainEvent.id };
  } catch (error) {
    return error;
  }

};

export const createMainEvent = async (data) => {
  try {
    let value = {}
    value.brideName = data.brideName || ''
    value.brideId = data.brideId || ''
    value.groomName = data.groomName || ''
    value.groomId = data.groomId || ''
    value.status = 'active'
    value.deleted = false
    value.createdBy = data.createdBy || ''
    value.createdAt = serverTimestamp()
    value.updatedAt = serverTimestamp()
    value.updatedBy = data.updatedBy || ''
    value.signup_with = data.signup_with || ''
    return addDoc(collection(db, 'MainEvents'), value);
  } catch (error) {
    Promise.reject(error)
  }
}
export const updateMainEvent = (id, data) => {
  return updateDoc(doc(db, 'MainEvents', id), data);
};

export const updateMainEventSubcollection = async (mainEventId, fileData) => {


  const subcollectionRef = collection(db, 'MainEvents', mainEventId, 'guestListFiles');

  try {
    const subcollection = await getDocs(subcollectionRef);
    if (subcollection.empty) {
      // Create the subcollection if it doesn't exist
      await addDoc(subcollectionRef, { files: arrayUnion(fileData) });
    }
    else {
      const guestListFileDoc = subcollection.docs[0];

      // Get the current files array
      const docSnapshot = await getDoc(guestListFileDoc.ref);
      const files = docSnapshot.data().files;

      // Update the first element of the files array
      files[0] = fileData;

      // Update the files field in the guestListFile document
      await updateDoc(guestListFileDoc.ref, { files: files });
    }

    console.log('File data added successfully!');

  } catch (error) {
    console.error('Error checking for subcollection: ', error);
  }

};