import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    category: '',
    name: '',
    state: '',
    address1: '',
    address2: '',
    contractDate: '',
    scheduleDetails: [{
        fromDate: '',
        toDate: '',
        venue: '',
        date: '',
        event: ''
    }],
    validFields: [],
    myEventList: [],
    amount: '',
    deposit: '',
    depositDate: '',
    contract: '',
    note: '',
    categoryList: [],
    categoryOptions: [],
    stateOptions: [],
    eventList: [],
    eventListOptions: [],
    isEdit: false,
    city: '',
    cityId: ''

};

export const uploadVendorDetailsSlice = createSlice({
  name: "uploadVendorDetails",
  initialState,
  reducers: {
    resetDetails: (state, action) => {
      state.category = "";
      state.name = "";
      state.state = "";
      state.address1 = "";
      state.address2 = "";
      state.contractDate = "";
      state.scheduleDetails = [
        {
          fromDate: "",
          toDate: "",
          venue: "",
          date: "",
          event: "",
        },
      ];
      state.validFields = [];
      state.myEventList = [];
      state.amount = "";
      state.deposit = "";
      state.depositDate = "";
      state.contract = "";
      state.note = "";
      state.categoryList = [];
      state.categoryOptions = [];
      state.stateOptions = [];
      state.eventList = [];
      state.eventListOptions = [];
      state.isEdit = false;
      state.city = "";
      state.cityId = "";
    },
    setScheduleDetais: (state, action) => {
      let { payload } = action;
      if (payload.hasOwnProperty("index")) {
        const newArray = [...state.scheduleDetails];
        newArray[payload.index] = payload.scheduleDetails;
        state.scheduleDetails = newArray;
      } else {
        state.scheduleDetails = payload.scheduleDetails || [];
      }
    },
    setValidFieldDetais: (state, action) => {
      let { payload } = action;
      if (payload.hasOwnProperty("index")) {
        const newArray = [...state.validFields];
        newArray[payload.index] = payload.validFields;
        state.validFields = newArray;
      } else {
        state.validFields = payload.validFields || [];
      }
    },
    modifyDetailsFieldArrayElement: (state, action) => {
      let { payload } = action;
      const newArray = [...state[payload.stateField]];
      if (payload.details) {
        newArray[payload.index] = payload.details;
      }
      if (payload.field) {
        newArray[payload?.index][payload?.field] = payload.value;
      }
      state[payload.stateField] = newArray || [];
    },
    modifyDetailsArray: (state, action) => {
      let { payload } = action;
      const newArray = [...state[payload.field]];
      if (payload.details) {
        newArray.splice(payload.startIndex, payload.count, payload.details);
      } else {
        newArray.splice(payload.startIndex, payload.count);
      }
      state[payload.field] = newArray || [];
    },
    updateVendorDetails: (state, action) => {
      let { payload } = action;
      if (payload.fields?.length > 0) {
        payload.fields.map((field) => (state[field.label] = field.value));
      }
    },
    updateVendorContractDetails: (state, action) => {
      let { payload } = action;
      state.category = payload.details.category;
      state.name = payload.details.name;
      state.state = payload.details.state;
      state.address1 = payload.details.address1;
      state.address2 = payload.details.address2;
      state.contractDate = payload.details.contractDate;
      state.amount = payload.details.amount;
      state.deposit = payload.details.deposit;
      state.depositDate = payload.details.depositDate;
      state.contract = payload.details.contract;
      state.note = payload.details.note;
      state.myEventList = payload.details.myEventList;
      state.scheduleDetails = payload.details.scheduleDetails;
      state.city = payload.details.city;
      state.cityId = payload.details.cityId;
    },
  },
});

export const {
    resetDetails,
    setScheduleDetais,
    updateVendorDetails,
    modifyDetailsArray,
    modifyDetailsFieldArrayElement,
    updateVendorContractDetails
} = uploadVendorDetailsSlice.actions

export default uploadVendorDetailsSlice.reducer