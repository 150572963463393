import { createSlice } from "@reduxjs/toolkit";
import { errorCodes } from "../../config/firebase/errors";
import { signUp, signIn, signOut, loadUserData } from "./userActions";
import { Routes } from '../../config/constants';


const initialState = {
    user: {},
    loading: false,
    error: {},
    method: null,
    pageLoading: false
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        reset: (state) => {
            state.user = {};
            state.loading = false;
            state.error = {};
            state.method = null;
            state.pageLoading=false;
            state.showModal=true;
        },
        init: () => { },
    },
    extraReducers: {
        [signUp.pending]: (state) => {
            state.method = 'signUp'
            state.loading = true;
        },
        [signUp.fulfilled]: (state, action) => {
            state.method = 'signUp'
            state.loading = false;
            state.user = action.payload;
        },
        [signUp.rejected]: (state, action) => {
            state.method = 'signUp'
            state.loading = false;
            let { code, message } = errorCodes[action.payload.code];
            state.error = {
                code,
                message
            }
        },
        [signIn.pending]: (state) => {
            state.method = 'signIn'
            state.loading = true;
        },
        [signIn.fulfilled]: (state, action) => {
            state.method = 'signIn'
            state.loading = false;
            state.user = action.payload;
            state.showModal=true;
        },
        [signIn.rejected]: (state, action) => {
            state.method = 'signIn'
            state.loading = false;
            let { code, message } = errorCodes[action.payload.code];
            state.error = {
                code,
                message
            }
        },
        [loadUserData.pending]: (state) => {
            state.method = 'loadUserData'
            state.loading = true;
        },
        [loadUserData.fulfilled]: (state, action) => {
            state.method = 'loadUserData'
            state.loading = false;
            state.user = action.payload;
        },
        [loadUserData.rejected]: (state, action) => {
            state.method = 'loadUserData'
            state.loading = false;
            let { code, message } = errorCodes[action?.payload?.code || "SOMETHING_WENT_WRONG"];
            state.error = {
                code,
                message
            }
        },
        [signOut.pending]: (state) => {
            state.method = 'signOut'
            state.loading = true;
            state.pageLoading=true;

        },
        [signOut.fulfilled]: (state) => {
            state.method = 'signOut'
            state.loading = false;
            state.user = {};
            state.error = {};
            window.location.href = Routes.LOGIN;
            state.pageLoading=false;
        },
        [signOut.rejected]: (state, action) => {
            state.method = 'signOut'
            state.loading = false;
            state.pageLoading=false;
            let { code, message } = errorCodes[action.payload.code];
            state.error = {
                code,
                message
            }
        }
    }
})

export const useUserLogin = () => {
    const user = useSelector(({ user }) => user);
    return [signUp, user];
}
export const useUserSignUp = () => {
    const user = useSelector(({ user }) => user);
    return [signIn, user];
};
export const useUserSignOut = () => {
    const user = useSelector(({ user }) => user);
    return [signOut, user];
};
export const useUserData = () => {
    const user = useSelector(({ user }) => user);
    return [loadUserData, user];
};

export const { reset, init } = userSlice.actions

export default userSlice.reducer;