import { createAsyncThunk } from '@reduxjs/toolkit';
import { userSpecificVendorCategory } from '../../modules/dashboard/vendors/controller';

export const getVendorCategoryDetails = createAsyncThunk(
    'vendorCategory/list',
    async ({ mainEventId }, { rejectWithValue }) => {
        try {
            let categoryList = await userSpecificVendorCategory(mainEventId)
            let categories = categoryList.map(category => {
                try {
                const data = {
                    categoryName: category?.details?.name
                }

                let keys = ["imageUrl", "description", "videoUrl"]
                keys.forEach(key => data[key] = category?.details?.[key])

                keys = [
                    "acceptAgreement",
                    "categoryId",
                    "contractDetails",
                    "id",
                    "locked",
                    "sortOrder",

                    "categoryQuizResponseId",
                    "rejectedVendorIds",
                    "suggestedVendorsDocPath",
                    "suggestedVendorsEventIds",
                    "haveVMAIntegration"
                ]
                keys.forEach(key => data[key] = category?.[key])

                keys = [
                    "callScheduled",
                    "doesHaveContract",
                    "isGroomGlamQuizCompleted",
                    "isBrideGlamQuizCompleted",
                    "isPersonalityQuizCompleted",
                    "isReccomendationChoosed",
                    "needGlamQuiz",
                    "needPersonalityQuiz",
                    "reccomendationPopup",
                    "surveyCompleted",
                    "vendorAssigned",
                    "venueConfirmed"
                ]
                keys.forEach(key => data[key] = category?.[key] || false)

                return data
            } catch (error) {
                console.log('❌ ERROR',error )
                }

            })
            return categories
        } catch (error) {
            return rejectWithValue({ code: error.code, message: error.message });
        }
    }
);
